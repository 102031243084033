<template>
  <v-app>
    <v-app-bar
      app
      elevate-on-scroll
      shrink-on-scroll
      color="black"
      dark
      height="100"
      scroll-threshold="500"
      elevation="12"
      fade-img-on-scroll
      ref="barChild"
    >
      <!-- class="app-bar-bg-img" -->
      <v-container fluid class="ma-0 pa-0" fill-height>
        <v-row class="mr-4 ml-4" justify="center" align="center">
          <v-img
            @click="imageclick"
            class="shrink hidden-sm-and-down"
            :width="img_width + 60"
            src="@/assets/naktos.png"
            eager
          />
          <v-spacer></v-spacer>
          <v-btn text to="/home" class="text-body-2 mr-6"> Αρχικη</v-btn>

          <!-- <v-btn text to="/honey"> Το Μελι μας</v-btn> -->

          <!-- <v-btn text to="/oliveoil"> προϊόντα</v-btn> -->

          <v-btn text to="/about" class="text-body-2 mr-6">Η ΙΣΤΟΡΙΑ ΜΑΣ</v-btn>

          <v-btn text to="/oliveoil" class="text-body-2 mr-6"> Ελαιολαδο</v-btn>

          <v-btn text to="/honey" class="text-body-2 mr-6"> Μελι</v-btn>

          <v-btn text to="/health" class="text-body-2 mr-6"> ΥΓΕΙΑ</v-btn>

          <!-- <v-menu open-on-hover transition="fab-transition" offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                text
                color="white"
                dark
                v-bind="attrs"
                v-on="on"
                class="text-body-1 mr-6"
              >
                προϊοντα
              </v-btn>
            </template>
            <v-list dense nav>
              <v-list-item to="/oliveoil">
                <v-list-item-title class="text-body-2 mr-6"
                  >Ελαιόλαδο</v-list-item-title
                >
              </v-list-item>
              <v-list-item>
                <v-list-item-title class="text-body-2 mr-6"
                  >Μέλι</v-list-item-title
                >
              </v-list-item>
            </v-list>
          </v-menu> -->

          <v-btn text to="/contact" class="text-body-2 mr-6">
            Επικοινωνια</v-btn
          >

          <v-spacer></v-spacer>
          <v-img
            @click="imageclick"
            class="shrink hidden-sm-and-down move"
            contain
            src="@/assets/logo.png"
            transition="scale-transition"
            :width="img_width"
            eager
          />
          <!-- <v-app-bar-nav-icon
            @click.stop="drawer = !drawer"
          ></v-app-bar-nav-icon> -->
        </v-row>
      </v-container>
    </v-app-bar>

    <!-------------------------------NAVIGATION DRAWER-------------------------------------->

    <v-navigation-drawer
      v-model="drawer"
      right
      temporary
      color="white"
      app
      floating
      width="25%"
      height="250px"
      class="center"
    >
      <!-- width="240px" -->
      <!-- :expand-on-hover="true"
      expand-on-hover-->

      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title, text-center"
            ><h1>NAKTOS</h1></v-list-item-title
          >
          <v-list-item-subtitle class="text-center"
            >PREMIUM</v-list-item-subtitle
          >
        </v-list-item-content>
      </v-list-item>
      <v-row justify="center">
        <v-btn @click="drawer = false" icon dark large color="primary">
          <v-icon dark> mdi-window-close </v-icon>
        </v-btn>
      </v-row>
      <v-list dense nav>
        <v-list-item v-for="item in items" :key="item.title" :to="item.route">
          <v-list-item-icon>
            <v-icon>mdi-{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <!-- <template v-slot:append>
        <div class="pa-2">
          <v-btn style="width: 100%" plain>
            <v-icon>mdi-plus</v-icon>
            New list
          </v-btn>
        </div>
      </template> -->
    </v-navigation-drawer>
    <!-------------------------------END NAVIGATION DRAWER-------------------------------------->
    <v-main>
      <v-container class="pa-0 common" fluid>
        <transition name="scale" mode="out-in">
          <!-- <v-slide-x-reverse-transition mode="in-out"> -->
          <!-- style="max-width: 1500px" -->

          <router-view />
          <!-- </v-slide-x-reverse-transition> -->
        </transition>
      </v-container>
    </v-main>

    <v-footer padless>
      <v-card tile width="100%" class="black" dark>
        <v-card-text class="text-center">
          © 2021 ΝΑΚΤΟΣ. All rights reserved | Designed & Developed by DOT
          STUDIO.
        </v-card-text>

        <v-divider dark></v-divider>

        <v-card-text class="white--text, text-center">
          {{ new Date().getFullYear() }} — <strong>ΝΑΚΤΟΣ</strong>
        </v-card-text>
      </v-card>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  components: {},
  props: {
    // source: String
  },
  data: () => ({
    drawer: false,
    img_width: 80,
    items: [
      { title: "facebook", icon: "facebook", route: "/facebook" },
      { title: "instagram", icon: "instagram", route: "/instagram" },
      { title: "tiktok", icon: "tiktok", route: "/tiktok" },
    ],
    styleObject: {
      "margin-top": "0px",
    },
  }),
  computed: {
    // bar_height: {
    //   get: function () {
    //     return 180;
    //   },
    //   set: function (newValue) {
    //     console.log("The value is set to computed ", newValue);
    //   },
    // },
  },
  watch: {
    // "this.$refs.barChild.height": {
    //   handler() {
    //     console.log("changed");
    //   },
    //   deep: true,
    //   immediate: true,
    // },
  },
  mounted() {
    this.$watch("$refs.barChild.computedHeight", (newValue) => {
      console.log("height", newValue);
      this.img_width = newValue - 20;
    });
    //console.log("mounted value", this.bar_height);
    //console.log("mounted value 2", this.$refs.barChild);
    document.addEventListener("mousemove", (event) => {
      //console.log("x", event.clientX);
      this.mousePosX = event.clientX;
      this.mousePosY = event.clientY;
      // document.getElementByClassName("logo").offset({
      //   left: event.clientX,
      //   top: event.clientY + 20,
      // });
    });
  },
  methods: {
    imageclick() {
      //console.log("configure company");

      this.$router.push("/");
    },
  },
  // mounted() {
  //   this.$watch("$refs.barChild.height", (newValue) => console.log(newValue));
  // },
  // created() {
  //   console.log("test", this.$refs);
  // },
  beforeDestroy() {},
  beforeCreate() {},
};
</script>

<style>
.scale-enter-active {
  transition: all 0.7s ease;
}
.scale-leave-active {
  transition: all 0.7s ease;
}

.scale-enter-from,
.scale-leave-to {
  opacity: 0;
  transform: scale(0.8);
}
.al {
  /* border: 3px solid red; */
  /* top: 100px; */

  text-align: center;
}

.v-toolbar__content {
  /* border: 3px solid green; */
  /* padding: 10px; */
}
.v-btn__content {
  /* border: 3px solid red; */
}
.v-btn--active {
  border-radius: 0px;
  border-bottom: 2px solid white;
}
.theme--dark.v-btn--active:hover::before,
.theme--dark.v-btn--active::before {
  opacity: 0;
}
.theme--dark.v-btn:hover::before {
  opacity: 0;
}
.theme--dark.v-btn:hover {
  /* background-color: aqua; */
  border-radius: 0px;
  border-bottom: 3px solid white;
}
.common {
  /* font-size: 0.8em; */
  /* font-weight: 500 !important; */
  /* background-color: #043344; */
  background-color: #ffffff;
  color: rgb(9, 9, 9);
  /* background-image: linear-gradient(
      rgba(0, 0, 255, 0.3),
      rgba(255, 255, 0, 0.3)
    ),
    url("@/assets/logo.png"); */

  /* background-color: rgba(107, 91, 91, 0.5); */
  /* background-size: 700px 1500px; */

  isolation: isolate;
  /* background-size: cover; */
  /* background-repeat: no-repeat; */
}
.common::after {
  content: "";
  opacity: 0.1;
  position: absolute;
  z-index: -1;
  inset: 0;

  background-image: url("@/assets/logo2.png");
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
}
.center {
  margin-top: 50%;
}
.app-bar-bg-img {
  background-image: linear-gradient(
    rgba(48, 48, 69, 0.5),
    rgba(166, 152, 149, 0.5)
  );
}
/* .text-body-1 {
  font-family: "Cardo", serif !important;
}
.P {
  font-family: "Cardo", serif !important;
} */
.img_frame {
  border: 12px solid;
  /* border-color: #e8d9ba; */
  border-color: #000000;
  border-radius: 5px;
}
.elevation {
  box-shadow: 7px 7px 5px #000000;
}
</style>
