import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "intro",
    meta: {
      layout: "EntranceLayout",
      requiresAuth: false,
      role: ["Root"],
    },
    component: () =>
      import(/* webpackChunkName: "main" */ "../views/IntroView.vue"),
  },
  {
    path: "/home",
    name: "home",
    meta: {
      layout: "MainLayout",
      requiresAuth: false,
      role: ["Root"],
    },
    component: () =>
      import(/* webpackChunkName: "main" */ "../views/HomeView.vue"),
  },
  {
    path: "/about",
    name: "about",
    meta: {
      layout: "MainLayout",
      requiresAuth: false,
      role: ["Root"],
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "main" */ "../views/AboutView.vue"),
  },
  {
    path: "/about",
    name: "about",
    meta: {
      layout: "MainLayout",
      requiresAuth: false,
      role: ["Root"],
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "main" */ "../views/AboutView.vue"),
  },
  {
    path: "/oliveoil",
    name: "oliveoil",
    meta: {
      layout: "MainLayout",
      requiresAuth: false,
      role: ["Root"],
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "main" */ "../views/OliveOil.vue"),
  },
  {
    path: "/honey",
    name: "honey",
    meta: {
      layout: "MainLayout",
      requiresAuth: false,
      role: ["Root"],
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "main" */ "../views/HoneyView.vue"),
  },
  {
    path: "/health",
    name: "health",
    meta: {
      layout: "MainLayout",
      requiresAuth: false,
      role: ["Root"],
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "main" */ "../views/HealthView.vue"),
  },
  {
    path: "/contact",
    name: "contact",
    meta: {
      layout: "MainLayout",
      requiresAuth: false,
      role: ["Root"],
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "main" */ "../views/ContactView.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
