<template>
  <v-app>
    <v-main>
      <transition name="scale" mode="out-in">
        <!-- <v-slide-x-reverse-transition mode="in-out"> -->
        <router-view />
        <!-- </v-slide-x-reverse-transition> -->
      </transition>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",
  data: () => ({
    showMenu: false,
    x: 0,
    y: 0,
  }),

  methods: {
    show(e) {
      e.preventDefault();
      this.showMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        this.showMenu = true;
      });
    },
  },
};
</script>

<style>
.scale-enter-active,
.scale-leave-active {
  transition: all 0.7s ease;
}

.scale-enter-from,
.scale-leave-to {
  opacity: 0;
  transform: scale(0.8);
}
.al {
  /* border: 3px solid red; */
  /* top: 100px; */
  position: relative;
  top: 35%;
}

.v-toolbar__content {
  /* border: 3px solid green; */
  /* padding: 10px; */
}
.v-btn__content {
  /* border: 3px solid red; */
}
.v-btn--active {
  border-bottom: 3px solid white;
}
.theme--dark.v-btn--active:hover::before,
.theme--dark.v-btn--active::before {
  opacity: 0;
}
.theme--dark.v-btn:hover::before {
  opacity: 0;
}
.theme--dark.v-btn:hover {
  /* background-color: aqua; */
  border-bottom: 3px solid white;
}
</style>
