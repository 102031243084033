<template>
  <v-app>
    <component :is="layout">
      <router-view />
    </component>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    //
  }),
  computed: {
    layout() {
      //console.log("->>>>", this.$route.meta.layout);
      return this.$route.meta.layout; //|| "div";
    },
  },
};
</script>
